var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        persistent: _setup.props.persistent,
        "max-width": "500",
      },
      model: {
        value: _setup.isOpen,
        callback: function ($$v) {
          _setup.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _setup.isOpen
        ? _c(
            "v-card",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _setup.onClickOutSide,
                  expression: "onClickOutSide",
                },
              ],
              staticClass: "mx-auto",
            },
            [
              _c(
                "v-card-title",
                [
                  _c("header", { staticClass: "app-welcome-message" }, [
                    _c("h2", [_vm._v(_vm._s(_setup.props.title))]),
                  ]),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { staticClass: "register-form" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                { attrs: { for: "party-add-logo" } },
                                [_vm._v(_vm._s(_setup.props.body))]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _setup.props.showOk
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              on: { click: _setup.onClickOkButton },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v(" mdi-check "),
                              ]),
                              _vm._v(" " + _vm._s(_setup.props.okLabel) + " "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _setup.props.showDelete
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "error",
                              on: { click: _setup.onClickDeleteButton },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v(" mdi-delete "),
                              ]),
                              _vm._v(
                                " " + _vm._s(_setup.props.deleteLabel) + " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { on: { click: _setup.onClickCancelButton } },
                        [
                          _c("v-icon", { attrs: { dark: "", left: "" } }, [
                            _vm._v(" mdi-cancel "),
                          ]),
                          _vm._v(_vm._s(_setup.props.cancelLabel) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }