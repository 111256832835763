var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-location", attrs: { fluid: "", "pa-0": "" } },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        _c("h2", [
          _vm.addModus
            ? _c("span", [_vm._v("Label toevoegen")])
            : _c("span", [_vm._v("Label wijzigen")]),
        ]),
      ]),
      _c(
        _setup.ValidationObserver,
        { ref: "obs" },
        [
          _c(
            "v-form",
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pa-0": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "masonry",
                            {
                              attrs: {
                                cols: { default: 2, 1264: 1 },
                                gutter: 12,
                              },
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "boxed", attrs: { fluid: "" } },
                                [
                                  _c("header", [_vm._v("Label")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Label",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "Label",
                                                        items:
                                                          _setup.energyLabels,
                                                        value:
                                                          _setup.label
                                                            .energyLabel,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.energyLabel =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "EnergyIndex",
                                              rules: "decimal:4|between:0,9",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label: "Energie-index",
                                                        value:
                                                          _setup.label
                                                            .energyIndex,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.energyIndex =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Bron",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "Bron",
                                                        items:
                                                          _setup.labelSources,
                                                        "item-text":
                                                          "description",
                                                        "item-value": "key",
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.label
                                                            .sourceKey,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.label,
                                                            "sourceKey",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "label.sourceKey",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Bron referentie",
                                              "persistent-placeholder": "",
                                              readonly: _setup.showEdit,
                                              outlined: !_setup.showEdit,
                                            },
                                            model: {
                                              value:
                                                _setup.label.sourceReference,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.label,
                                                  "sourceReference",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "label.sourceReference",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                { staticClass: "boxed", attrs: { fluid: "" } },
                                [
                                  _c("header", [_vm._v("Energie")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "EnergyRequirement",
                                              rules:
                                                "decimal:4|between:0,9999999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "EP 1: Energiebehoefte [kWh/m²]",
                                                        value:
                                                          _setup.label
                                                            .energyDemand,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.energyDemand =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "PrimaryFossilEnergyUse",
                                              rules:
                                                "decimal:2|between:0,9999999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "EP 2: Primair fossiel energieverbruik [kWh/m²]",
                                                        value:
                                                          _setup.label
                                                            .primaryFossilEnergy,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.primaryFossilEnergy =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "PrimaryFossilEnergyForfaitair",
                                              rules:
                                                "decimal:2|between:0,9999999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "EP 2 EMG forf.: Primair fossiel energieverbruik [kWh/m²]",
                                                        value:
                                                          _setup.label
                                                            .primaryFossilEnergyForfaitair,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.primaryFossilEnergyForfaitair =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "ShareOfRenewableEnergy",
                                              rules:
                                                "decimal:4|between:0,9999999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "EP 3: Aandeel hernieuwbare energie [kWh/m²]",
                                                        value:
                                                          _setup.label
                                                            .shareOfRenewableEnergy,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.shareOfRenewableEnergy =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "masonry",
                            {
                              attrs: {
                                cols: { default: 2, 1264: 1 },
                                gutter: 12,
                              },
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "boxed", attrs: { fluid: "" } },
                                [
                                  _c("header", [
                                    _vm._v("Kenmerken energielabel"),
                                  ]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsableSurface",
                                              rules:
                                                "decimal:2|between:0,9999999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Ag: Gebruiksoppervlakte [m²]",
                                                        value:
                                                          _setup.label
                                                            .usableSurface,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usableSurface =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsableLossSurface",
                                              rules:
                                                "decimal:2|between:0,9999999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Als: Verliesoppervlakte [m²]",
                                                        value:
                                                          _setup.label
                                                            .usableLossSurface,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usableLossSurface =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsableGeometry",
                                              rules:
                                                "decimal:2|between:0,9999999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Als/Ag: Geometrieverhouding [-]",
                                                        value:
                                                          _setup.label
                                                            .usableGeometry,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usableGeometry =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "CO2",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "CO₂ uitstoot [kg]",
                                                        value: _setup.label.co2,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.co2 =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "DemandHeatNet",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Netto warmtebehoefte (EPV) [kWh/m²]",
                                                        value:
                                                          _setup.label
                                                            .demandHeatNet,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.demandHeatNet =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "EnergyStandard",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Standaard [kWh/m²]",
                                                        value:
                                                          _setup.label
                                                            .energyStandard,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.energyStandard =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "EnergyRenewable",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Hernieuwbare energie [kWh/m²]",
                                                        value:
                                                          _setup.label
                                                            .energyRenewable,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.energyRenewable =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageElectricity",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Elektriciteitsverbruik [kWh]",
                                                        value:
                                                          _setup.label
                                                            .usageElectricity,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageElectricity =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageGas",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Gasverbruik [m³ aeq]",
                                                        value:
                                                          _setup.label.usageGas,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageGas =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageHeat",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Warmteverbruik [GJ]",
                                                        value:
                                                          _setup.label
                                                            .usageHeat,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageHeat =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                {
                                  staticClass: "boxed",
                                  class: { noBorders: false },
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c("header", [_vm._v("Energiebalans")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "FossilEnergyUsageNonPrimary",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "EP 2 niet primair [kWh/m²]",
                                                        value:
                                                          _setup.label
                                                            .fossilEnergyUsageNonPrimary,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.fossilEnergyUsageNonPrimary =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "TotalUsageNonPrimary",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Totale deelposten (niet primair) [kWh]",
                                                        value:
                                                          _setup.label
                                                            .totalUsageNonPrimary,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.totalUsageNonPrimary =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageVentilation",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Ventilatie [kWh]",
                                                        value:
                                                          _setup.label
                                                            .usageVentilation,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageVentilation =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageHeating",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Verwarming [kWh]",
                                                        value:
                                                          _setup.label
                                                            .usageHeating,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageHeating =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageWater",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label: "Tapwater [kWh]",
                                                        value:
                                                          _setup.label
                                                            .usageWater,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageWater =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageCooling",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label: "Koeling [kWh]",
                                                        value:
                                                          _setup.label
                                                            .usageCooling,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageCooling =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageHumidification",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Bevochtiging [kWh]",
                                                        value:
                                                          _setup.label
                                                            .usageHumidification,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageHumidification =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageDehumidification",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Ontvochtiging [kWh]",
                                                        value:
                                                          _setup.label
                                                            .usageDehumidification,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageDehumidification =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageLighting",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Verlichting [kWh]",
                                                        value:
                                                          _setup.label
                                                            .usageLighting,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageLighting =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageAuxiliaryElectrical",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Hulpenergie elektrisch [kWh]",
                                                        value:
                                                          _setup.label
                                                            .usageAuxiliaryElectrical,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageAuxiliaryElectrical =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageAuxiliaryPilotFlame",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Hulpenergie waakvlam [kWh]",
                                                        value:
                                                          _setup.label
                                                            .usageAuxiliaryPilotFlame,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageAuxiliaryPilotFlame =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UsageElectricityGeneration",
                                              rules:
                                                "decimal:4|between:0,99999999999999.9999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Elektriciteitsopwekking [kWh]",
                                                        value:
                                                          _setup.label
                                                            .usageElectricityGeneration,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.usageElectricityGeneration =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "masonry",
                            { attrs: { cols: { default: 1 }, gutter: 12 } },
                            [
                              _c(
                                "v-container",
                                { staticClass: "boxed", attrs: { fluid: "" } },
                                [
                                  _c("header", [_vm._v("Warmtebehoefte")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "DemandHeat",
                                              rules:
                                                "decimal:2|between:0,9999999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Warmtebehoefte (QH;nod;in) [kWh]",
                                                        value:
                                                          _setup.label
                                                            .demandHeat,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.demandHeat =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "DemandHotWater1",
                                              rules:
                                                "decimal:2|between:0,9999999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Warmtapwaterbehoefte systeem 1 [kWh]",
                                                        value:
                                                          _setup.label
                                                            .demandHotWater1,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.demandHotWater1 =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "DemandCold",
                                              rules:
                                                "decimal:2|between:0,9999999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Koudebehoefte [kWh]",
                                                        value:
                                                          _setup.label
                                                            .demandCold,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.demandCold =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "DemandHotWater2",
                                              rules:
                                                "decimal:2|between:0,9999999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Warmtapwaterbehoefte systeem 2 [kWh]",
                                                        value:
                                                          _setup.label
                                                            .demandHotWater2,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.demandHotWater2 =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "masonry",
                            {
                              attrs: {
                                cols: { default: 2, 1264: 1 },
                                gutter: 12,
                              },
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "boxed", attrs: { fluid: "" } },
                                [
                                  _c("header", [_vm._v("Geldigheid")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            ref: "recordedDate",
                                            attrs: {
                                              name: "Opnamedatum",
                                              rules: "required|date_between",
                                              mode: _setup.checkDateValid,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(_setup.DateInput, {
                                                      attrs: {
                                                        label:
                                                          "Opnamedatum (bepalend voor de leeftijd van het label)",
                                                        errors: errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        rules: "required",
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.label
                                                            .recordedDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.label,
                                                            "recordedDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "label.recordedDate",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            ref: "registrationDate",
                                            attrs: {
                                              name: "Registratiedatum",
                                              rules: "date_between",
                                              mode: _setup.checkDateValid,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(_setup.DateInput, {
                                                      attrs: {
                                                        label:
                                                          "Registratiedatum",
                                                        errors: errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        rules: "required",
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.label
                                                            .registrationDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.label,
                                                            "registrationDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "label.registrationDate",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Registratienummer",
                                              rules: "between:0,999999999",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Registratienummer",
                                                        value:
                                                          _setup.label
                                                            .registrationNumber,
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          _setup.showEdit,
                                                        outlined:
                                                          !_setup.showEdit,
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.label.registrationNumber =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                {
                                  staticClass: "boxed",
                                  class: { noBorders: false },
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c("header", [_vm._v("Aanpassing")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Door",
                                              readonly: "",
                                              outlined: false,
                                              "persistent-placeholder": "",
                                            },
                                            model: {
                                              value: _setup.label.modBy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.label,
                                                  "modBy",
                                                  $$v
                                                )
                                              },
                                              expression: "label.modBy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Datum",
                                              readonly: "",
                                              outlined: false,
                                              "persistent-placeholder": "",
                                            },
                                            model: {
                                              value: _setup.mutDateFormatted,
                                              callback: function ($$v) {
                                                _setup.mutDateFormatted = $$v
                                              },
                                              expression: "mutDateFormatted",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "form-group", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { disabled: _setup.disableSave },
                              on: {
                                click: function ($event) {
                                  return _setup.onSave()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-checkbox-marked-circle"),
                              ]),
                              _vm._v(" Opslaan "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "form-group", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _setup.onCancel()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-cancel"),
                              ]),
                              _vm._v(" Annuleren "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      !_vm.addModus
                        ? _c(
                            "v-col",
                            {
                              staticClass: "col col-auto",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "secondary",
                                  attrs: { disabled: _setup.isSaving },
                                  on: { click: _setup.onDeleteLabel },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-delete")]
                                  ),
                                  _vm._v(" Verwijderen "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(_setup.EnergyLabelSearch, {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.addModus,
            expression: "addModus",
          },
        ],
        on: { selected: _setup.selectedEnergyLabel },
      }),
      _c(_setup.AlertOkCancel, {
        attrs: {
          title: "Verwijderen energielabel",
          showDelete: "",
          body: _setup.modalDeleteBody,
          persistent: false,
          modalOpen: _setup.modalDeleteOpen,
        },
        on: { onDialogResult: _setup.onDeleteLabelDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }